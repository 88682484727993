import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import BACKGROUND_COLORS_CLASSES from '../constants/BACKGROUND_COLORS_CLASSES'

export const paginationCva = cva(
  [
    'p-2 transition h-10 w-10 relative after:absolute after:inset-0 after:z-10 after:opacity-0 overflow-hidden after:duration-300'
  ],
  {
    variants: {
      color: BACKGROUND_COLORS_CLASSES,
      type: {
        previous: ['rounded-l-md'],
        next: ['rounded-r-md']
      },
      disabled: {
        true: [
          'cursor-not-allowed opacity-50 after:bg-white hover:after:opacity-20'
        ],
        false: ['after:bg-black hover:after:opacity-10']
      }
    },
    defaultVariants: {
      color: 'accent1',
      disabled: false
    }
  }
)

export type PaginationProps = VariantProps<typeof paginationCva>
